import MissingRequiredFields from 'components/MissingRequiredFields';
import { ExtendedVerticalType, VerticalType } from 'models/product';
import React from 'react';

import PolicyLinkSectionComponent from '../../components/policyLink/section';
import { PolicyLinkSectionSlice } from '../../types.generated';
import styles from './PolicyLinkSection.module.scss';

export const PolicyLinkSection = ({
  slice,
}: {
  slice: PolicyLinkSectionSlice;
}) => {
  const {
    first_policy: firstPolicy,
    second_policy: secondPolicy,
    third_policy: thirdPolicy,
    fourth_policy: fourthPolicy,
    title,
  } = slice.primary;

  if (!firstPolicy && !secondPolicy && !thirdPolicy && !fourthPolicy)
    return <MissingRequiredFields />;

  const policies = [
    firstPolicy,
    secondPolicy,
    thirdPolicy,
    fourthPolicy,
  ].filter((policy) => !!policy && policy !== 'NONE');

  return (
    <section className={`mt48 ${styles.container}`}>
      {/* TODO: remove type assertion on the next line */}
      <PolicyLinkSectionComponent
        policies={policies as (VerticalType | ExtendedVerticalType)[]}
        title={title}
      />
    </section>
  );
};
